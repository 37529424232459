export const getMonthlyPaymentAmount = (loanAmount: number | undefined, termMonths: number | undefined, apr: number | undefined) => {
    if (loanAmount && termMonths && apr && termMonths > 0) {
        return Math.round(
            (
                Number(loanAmount) *
                Math.pow(1 + Number(apr) / 100, Number(termMonths) / 12)
            )
            / Number(termMonths)
            * 100) / 100;
    }
    return 0;
};

export const getTotalSavingsOverTermOfLoan = (
    loanAmount: number | undefined, termMonths: number | undefined, aprHigh: number | undefined, aprLow: number | undefined
) => {
    if (loanAmount && termMonths && termMonths > 0 && aprHigh && aprLow) {
        return (getMonthlyPaymentAmount(loanAmount, termMonths, aprHigh) -
            getMonthlyPaymentAmount(loanAmount, termMonths, aprLow)) * termMonths;
    }
    return 0;
};

// Convert Dollars to Cents
export const formatDollarsToCents = (value: string): number => {
  value = (value + '').replace(/[^\d.-]/g, '');
  if (value && value.includes('.')) {
    value = value.substring(0, value.indexOf('.') + 3);
  }
  const test = value ? Math.round(parseFloat(value) * 100) : 0;
  return value ? Math.round(parseFloat(value) * 100) : 0;
}

// Convert Cents to Dollars
export const formatCentsToDollars = (value: string): string => {  
  value = (value + '').replace(/[^\d.-]/g, '');
  const parsedValue = parseFloat(value);

  const dollarsNumber = value ? parsedValue / 100 : 0;
  
  return dollarsNumber.toFixed(2);
  // return value ? parsedValue / 100 : 0;
}

export const addMonths = (date: Date, months:number) => {
  const d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() != d) {
    date.setDate(0);
  }
  return date;
}
