import React from 'react';
import './beetsNotFound.css';
import { BEETS_SCREENS } from '../../../forms/memberOnboarding/MemberOnboardingBeetsCoffee';

type Props = {
  setScreen: (form: BEETS_SCREENS) => void;
};
export const BeetsNotFound: React.FC<Props> = ({ setScreen }) => {
  return (
    <>
      <div className={'beets-subscription-not-found-title'}>
        Sorry, we didn’t find you
      </div>

      <div className={'beets-subscription-not-found-subtitle'}>
        The information you entered does not indicate you are currently eligible
        to pay by paycheck with Highline
        <br />
        <br />
        If you think this is an error, please reach out to your testing
        administrator.
      </div>

      <button
        type="button"
        onClick={() => setScreen(BEETS_SCREENS.CHECK_EMPLOYEE)}
        className={'beets-subscription-not-found-button'}
      >
        Go back
      </button>
    </>
  );
};
