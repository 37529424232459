import React, { useEffect, useRef, useState } from 'react';
import './beetsSubscriptionForm.css';

export type BeetsEmployeeFormInputs = {
  first_name: string;
  last_name: string;
  employee_id: string;
};

type Props = {
  onSuccess: (values: BeetsEmployeeFormInputs) => void;
};

export const BeetsSubscriptionForm: React.FC<Props> = ({ onSuccess }) => {
  const focusInput = useRef<HTMLInputElement>(null);
  const [isFormSubmitButtonDisabled, setIsFormSubmitButtonDisabled] =
    useState(false);
  const [buttonName, setButtonName] = useState('Check if I’m eligible');

  useEffect(() => {
    focusInput?.current?.focus();
  }, []);

  const onFormSubmitHandler = (e: any) => {
    e.preventDefault();
    setButtonName('Checking...');
    setIsFormSubmitButtonDisabled(true);
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData) as BeetsEmployeeFormInputs;
    if (onSuccess) {
      onSuccess(data);
    }
  };

  return (
    <>
      <div className={'beets-subscription-form-title'}>
        COFFEE BEAN SUBSCRIPTION
      </div>

      <div className={'beets-subscription-form-subtitle'}>
        Enter your legal name and EmployeeID to see if you can pay by paycheck
      </div>

      <form
        action=""
        className={'beets-subscription-form'}
        onSubmit={onFormSubmitHandler}
      >
        <div className="beets-subscription-form-line1">
          <input
            ref={focusInput}
            type="text"
            name="first_name"
            placeholder="First Name"
            maxLength={50}
            disabled={isFormSubmitButtonDisabled}
            required
          />

          <input
            type="text"
            name="last_name"
            placeholder="Last Name"
            maxLength={50}
            disabled={isFormSubmitButtonDisabled}
            required
          />
        </div>

        <input
          type="password"
          name="employee_id"
          placeholder="Employee ID"
          maxLength={200}
          disabled={isFormSubmitButtonDisabled}
          required
        />

        <button disabled={isFormSubmitButtonDisabled} type="submit">
          {buttonName}
        </button>
      </form>
    </>
  );
};
