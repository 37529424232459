import axios, { AxiosResponse } from 'axios';
import { DemoApiRequestData } from '../types/memberOnboardingTypes';

const enrollmentApiAuthHeaders: { [key: string]: string } = {
  Authorization: 'Bearer ' + window.accessToken,
};

export const getAccessToken = (
  successCallback: (response: any) => void,
  errorCallback: (errorMessage: any) => void,
  config: any,
  productId: string,
  demoApiRequestData: DemoApiRequestData,
  beforeAction?: () => void,
  afterAction?: () => void
): void => {
  if (beforeAction) {
    beforeAction();
  }
  const demo_api_url = config.demo_api_url;
  axios
    .post(
      `${demo_api_url}/highline/sessions/new/${productId}`,
      demoApiRequestData
    )
    .then((response: AxiosResponse) => {
      console.log(response);
      if (response && response.data && response.data.access_token) {
        window.accessToken = response.data.access_token;
        successCallback(response.data);
      }
    })
    .catch((error: any) => {
      console.log(error);
      errorCallback(error);
    })
    .finally(() => {
      if (afterAction) {
        afterAction();
      }
    });
};

export const getUserMatch = (
  successCallback: (response: any) => void,
  errorCallback: (errorMessage: any) => void,
  config: any,
  ssn: string,
  first_name?: string,
  last_name?: string,
  beforeAction?: () => void,
  afterAction?: () => void
): void => {
  const apiUrl = config.enrollment_api_url;
  axios
    .post(
      `${apiUrl}/users/match`,
      { ssn, first_name, last_name },
      {
        headers: {
          Authorization: `Bearer ${window.accessToken}`,
        },
      }
    )
    .then((response: AxiosResponse) => {
      console.log(response);
      if (response && response.status === 200) {
        successCallback(response.data);
      } else {
        errorCallback(response);
      }
    })
    .catch((error: any) => {
      console.log(error);
      errorCallback(error);
    })
    .finally(() => {
      if (afterAction) {
        afterAction();
      }
    });
};

export const postUsersSimulateIdentity = (
  successCallback: (response: any) => void,
  errorCallback: (errorMessage: any) => void,
  config: any,
  beforeAction?: () => void,
  afterAction?: () => void
): void => {
  const apiUrl = config.enrollment_api_url;
  const today = new Date();
  const formattedDate = today.toISOString().slice(0, 10);
  axios
    .post(
      `${apiUrl}/users/simulate_identity`,
      {
        last_pay_date: formattedDate,
        employer_name: 'Amazon',
        pay_cycle: 'weekly',
      },
      {
        headers: {
          Authorization: `Bearer ${window.accessToken}`,
        },
      }
    )
    .then((response: AxiosResponse) => {
      console.log(response);
      if (response && response.status === 200) {
        successCallback(response.data);
      } else {
        errorCallback(response);
      }
    })
    .catch((error: any) => {
      console.log(error);
      errorCallback(error);
    })
    .finally(() => {
      if (afterAction) {
        afterAction();
      }
    });
};

export const postHighlineComplete = (
  successCallback: (response: any) => void,
  errorCallback: (errorMessage: any) => void,
  config: any,
  productId: string,
  payment_amount: number,
  connection_id: string,
  user_id: string,
  beforeAction?: () => void,
  afterAction?: () => void
): void => {
  const demo_api_url = config.demo_api_url;
  axios
    .post(
      `${demo_api_url}/highline/complete/${productId}`,
      { payment_amount, connection_id, user_id },
      {
        headers: {
          Authorization: `Bearer ${window.accessToken}`,
        },
      }
    )
    .then((response: AxiosResponse) => {
      console.log(response);
      if (response && response.data) {
        successCallback(response.data);
      }
    })
    .catch((error: any) => {
      console.log(error);
      errorCallback(error);
    })
    .finally(() => {
      if (afterAction) {
        afterAction();
      }
    });
};
