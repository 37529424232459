import { RefObject, useState } from 'react';
import './amountInput.css';

type Props = {
  forwardRef: RefObject<HTMLInputElement>;
  name: string;
  prev: RefObject<HTMLInputElement> | undefined;
  next: RefObject<HTMLInputElement> | undefined;
  onChange?: () => void;
  required?: boolean;
  disabled?: boolean;
};

export const AmountInput: React.FC<Props> = ({
  next,
  prev,
  forwardRef,
  name,
  required = false,
  onChange,
  disabled = false,
}) => {
  const [value, setValue] = useState('');

  const handleKeyDown = (e: any) => {
    if (/[0-9]/g.test(e.key)) {
      e.preventDefault();
      setValue(e.key);
      next?.current?.focus();
      if (onChange) onChange();
    }

    if (e.key === 'Backspace') {
      e.preventDefault();
      setValue('');
      prev?.current?.focus();
    }

    if (e.key === 'ArrowLeft' && prev?.current) {
      prev.current?.focus();
    }

    if (e.key === 'ArrowRight' && next?.current) {
      next.current?.focus();
    }
  };

  return (
    <input
      ref={forwardRef}
      name={name}
      className={'amount-input'}
      type="text"
      maxLength={1}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onKeyDown={handleKeyDown}
      required={required}
      disabled={disabled}
    />
  );
};
