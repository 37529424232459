import React, { useEffect, useRef, useState } from 'react';
import './beetsEnterAmountForm.css';
import { AmountInput } from '../amountInput';

type Props = {
  onSuccess: (amount: number) => void;
};

export const BeetsEnterAmountForm: React.FC<Props> = ({ onSuccess }) => {
  const input1 = useRef<HTMLInputElement>(null);
  const input2 = useRef<HTMLInputElement>(null);
  const input3 = useRef<HTMLInputElement>(null);
  const input4 = useRef<HTMLInputElement>(null);

  const [isFormSubmitButtonDisabled, setIsFormSubmitButtonDisabled] =
    useState(false);
  const [buttonName, setButtonName] = useState('Pay by Paycheck with Highline');
  const [error, setError] = useState('');

  useEffect(() => {
    document.title = "Highline - Beet's Coffee";
    (window as any).highline_link_close = highlineLinkClose;
  }, []);

  useEffect(() => {
    input1?.current?.focus();
  }, []);

  const highlineLinkClose = () => {
    setIsFormSubmitButtonDisabled(false);
    setButtonName('Pay by Paycheck with Highline');
  };

  const onFormSubmitHandler = (e: any) => {
    e.preventDefault();
    setButtonName('Checking...');
    setIsFormSubmitButtonDisabled(true);
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    const values: (number | string)[] = [];
    Object.values(data).forEach((value) => {
      values.push(+(value as string));
    });
    values.splice(-2, 0, '.');

    const amount = parseFloat(values.join(''));

    if (!isNaN(amount) && amount >= 4 && amount <= 15) {
      if (onSuccess) {
        onSuccess(amount);
      }
    } else {
      setButtonName('Pay by Paycheck with Highline');
      setIsFormSubmitButtonDisabled(false);
      setError('Please enter an amount between $4.00 and $15.00.');
    }
  };

  return (
    <div>
      <div className={'beets-enter-amount-form-title'}>
        You’re eligible to subscribe!
      </div>

      <div className={'beets-enter-amount-form-subtitle'}>
        Please enter an amount between $4.00 and $15.00.
        <br />
        This is just to provide testing variability in the amounts taken from
        testers paychecks
      </div>

      <form
        action=""
        className={'beets-enter-amount-form'}
        onSubmit={onFormSubmitHandler}
      >
        <div className="beets-enter-amount-form-row">
          <span className={'beets-enter-amount-form-dollar'}>$</span>
          <AmountInput
            forwardRef={input1}
            name={'input1'}
            prev={undefined}
            next={input2}
            onChange={() => setError('')}
            disabled={isFormSubmitButtonDisabled}
          />
          <AmountInput
            forwardRef={input2}
            name={'input2'}
            prev={input1}
            next={input3}
            onChange={() => setError('')}
            disabled={isFormSubmitButtonDisabled}
            required
          />
          <span className={'beets-enter-amount-form-dollar'}>.</span>
          <AmountInput
            forwardRef={input3}
            name={'input3'}
            prev={input2}
            next={input4}
            onChange={() => setError('')}
            disabled={isFormSubmitButtonDisabled}
          />
          <AmountInput
            forwardRef={input4}
            name={'input4'}
            prev={input3}
            next={undefined}
            onChange={() => setError('')}
            disabled={isFormSubmitButtonDisabled}
          />
        </div>

        {error && (
          <div className={'beets-enter-amount-form-error'}>{error}</div>
        )}

        <button disabled={isFormSubmitButtonDisabled} type="submit">
          {buttonName}
        </button>
      </form>
    </div>
  );
};
