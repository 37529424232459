import React from 'react';

type ConfigContextType = {
  config: any | null;
  setConfig: React.Dispatch<React.SetStateAction<any | null>>;
};

export const ConfigContext = React.createContext<ConfigContextType>({
  config: null,
  setConfig: () => {},
});
