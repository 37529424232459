import { HighlineLinkProps } from '../types/memberOnboardingTypes';
import { HighlineSuccessCallbackResponse } from '../interfaces/HighlineSuccessCallbackResponse';

declare global {
  interface Window {
    highlineLink: any;
    highline_link_success: any;
    highline_link_error: any;
    highline_link_close: any;
    highlineStep: number;
    enrollmentId: string;
    // payrollConnectionId: string;
    accessToken: string;
    feature: string;
    enrollmentUserId: string;
    payload: string;
  }
}

const highlineLinkJs: string = process.env.REACT_APP_HIGHLINE_LINK_JS || '';
const highlineLinkProps: HighlineLinkProps = {
  access_token: 'token_from_demo_api',
  feature: 'highline_link_feature',
  enrollment_id: 'key_from_enrollment_api',
};

export const runHighlineLinkConnection = (access_token: string) => {
  // const updatedHighlineLinkProps = {...highlineLinkProps};
  unsetHighlineWidgetLink();

  const callParams = {
    access_token: window.accessToken,
    feature: 'employment_data',
    on_success: (on_success_response: HighlineSuccessCallbackResponse) => {
      window.highline_link_success(on_success_response);

      // if (on_success_response.payroll_connection_id) {
      //     window.payrollConnectionId = on_success_response.payroll_connection_id;
      // }
    },
    on_error: () => {
      window.highline_link_error();
    },
    on_close: () => {
      window.highline_link_close();
    },
  };

  if (window.highlineLink) {
    console.log('callParams', callParams);
    window.highlineLink.connect(callParams);
    window.highlineLink.open();
  }
};

export const runHighlineLinkFlow2Payments = (
  access_token: string,
  feature: string,
  enrollment_id: string
) => {
  console.log('runHighlineLinkFlow2Payments');
  const updatedHighlineLinkProps = {
    ...highlineLinkProps,
    enrollment_id: enrollment_id,
  };
  // if (window.highlineStep !== 2) {
  //     unsetHighlineWidgetLink();
  // }
  const callParams = {
    access_token: access_token,
    feature: feature,
    enrollment_id: updatedHighlineLinkProps.enrollment_id,
    on_success: (on_success_response: HighlineSuccessCallbackResponse) => {
      window.highline_link_success(on_success_response);
    },
    on_error: () => {
      window.highline_link_error();
    },
    on_close: () => {
      window.highline_link_close();
    },
  };
  if (window.highlineLink) {
    console.log('callParams', callParams);
    window.highlineLink.connect(callParams);
    window.highlineLink.open();
  }
  return '';
};

export const runHighlineLink = (
  access_token: string,
  feature = 'direct_deposit_payment',
  enrollment_id: string,
  applicantToken: string | null = null
) => {
  const updatedHighlineLinkProps = {
    ...highlineLinkProps,
    enrollment_id: enrollment_id,
  };

  if (window.highlineStep !== 2) {
    unsetHighlineWidgetLink();
  }

  const callParams = {
    access_token: access_token,
    feature: feature,
    enrollment_id: enrollment_id,
    ...(!!applicantToken && { applicant_token: applicantToken }),
    on_success: () => {
      window.highline_link_success();
    },
    on_error: () => {
      window.highline_link_error();
    },
    on_close: () => {
      window.highline_link_close();
    },
  };

  if (window.highlineLink) {
    console.log('callParams', callParams);
    window.highlineLink.connect(callParams);
    window.highlineLink.open();
  }
  return '';
};

export const runHighlineLinkDepositSwitch = (
  access_token: string,
  feature = 'direct_deposit_switch',
  payload: string
) => {
  const updatedHighlineLinkProps = { ...highlineLinkProps, payload: payload };

  if (window.highlineStep !== 2) {
    unsetHighlineWidgetLink();
  }

  const callParams = {
    access_token: access_token,
    feature: feature,
    payload: payload,
    on_success: () => {
      window.highline_link_success();
    },
    on_error: () => {
      window.highline_link_error();
    },
    on_close: () => {
      window.highline_link_close();
    },
  };

  if (window.highlineLink) {
    console.log('callParams', callParams);
    window.highlineLink.connect(callParams);
    window.highlineLink.open();
  }
  return '';
};

export const runHighlineLinkDC = (
  access_token: string,
  feature = 'payments',
  applicantToken: string | null = null
) => {
  const updatedHighlineLinkProps = {
    ...highlineLinkProps,
  };

  if (window.highlineStep !== 2) {
    unsetHighlineWidgetLink();
  }

  const callParams = {
    access_token: access_token,
    feature: feature,
    ...(!!applicantToken && { applicant_token: applicantToken }),
    on_success: (params: any) => {
      window.highline_link_success(params);
    },
    on_error: () => {
      window.highline_link_error();
    },
    on_close: () => {
      window.highline_link_close();
    },
  };

  if (window.highlineLink) {
    console.log('callParams', callParams);
    window.highlineLink.init(callParams);
    window.highlineLink.open();
  }
  return '';
};

export const unsetHighlineWidgetLink = () => {
  try {
    for (let i = 0; i < 10; i++) {
      const widgetScript = document.getElementById('highlineLinkWidget');
      if (widgetScript !== null) {
        widgetScript.remove();
      }
    }
  } catch (e) {
    console.log(e);
  }
  try {
    const highlineLinkDomItem = document.getElementById('highline_link');
    if (highlineLinkDomItem !== null) {
      highlineLinkDomItem.innerHTML = '';
    }
  } catch (e) {
    console.log(e);
  }
  try {
    var classMatcher = /PluginContainer-/;
    var els = document.getElementsByTagName('*');
    for (var i = els.length; i--; ) {
      if (classMatcher.test(els[i].className)) {
        const parentNode = els[i].parentNode;
        if (parentNode != null) {
          parentNode.removeChild(els[i]);
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
};
