import React, { useState, useEffect } from 'react';
import { MemberOnboardingBeetsCoffee } from './forms/memberOnboarding/MemberOnboardingBeetsCoffee';
import { MemberOnboardingDPA } from './forms/memberOnboarding/MemberOnboardingDPA';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ConfigContext } from './ConfigContext';
import './App.css';

function App() {
  const [config, setConfig] = useState<any | null>(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    async function loadConfig() {
      const currentHost = window.location.host;
      let API_URL;

      if (currentHost === 'localhost:3000') {
        API_URL = 'http://localhost:8080';
      } else {
        API_URL = window.location.origin;
      }

      const res = await fetch(`${API_URL}/highline/config`);

      const configData = await res.json();
      configData.demo_api_url = API_URL;

      // TODO: USE TO TEST LOCALLY - REMOVE WHEN THE API WORKS LOCALLY
      // const res = await fetch(`https://try-it.highline.co/highline/config`);

      // let configData = await res.json();
      // configData.demo_api_url = API_URL;

      // https: configData.demo_api_url = 'https://try-it.highline.co';
      // configData.enrollment_api_url = 'https://sandbox-api.highline.co/v2';
      // configData.highline_link_js =
      //   'https://sdk.highline.co/js/sandbox/highline-link.js';

      setConfig(configData);
      const script = document.createElement('script');
      script.src = configData.highline_link_js;
      script.onload = () => {
        setScriptLoaded(true);
      };
      document.head.appendChild(script);
    }

    loadConfig();
  }, []);

  if (!config || !scriptLoaded) {
    return <div>Loading...</div>;
  }

  const currentHost = window.location.host;
  if (currentHost === 'beets.highline.co') {
    return (
      <ConfigContext.Provider value={{ config, setConfig }}>
        <Router>
          <Routes>
            <Route path="/" element={<MemberOnboardingBeetsCoffee />} />
          </Routes>
        </Router>
      </ConfigContext.Provider>
    );
  } else {
    return (
      <ConfigContext.Provider value={{ config, setConfig }}>
        <Router>
          <Routes>
            <Route path="/" element={<MemberOnboardingDPA />} />
          </Routes>
        </Router>
      </ConfigContext.Provider>
    );
  }
}

export default App;
