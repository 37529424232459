import React from 'react';
import './beetsSuccess.css';

export const BeetsSuccess: React.FC = () => {
  return (
    <div className={'beets-complete-wrapper'}>
      <div className={'beets-complete-title'}>
        <div className={'green-check'}></div>
        <div className={'thanks-text'}>
          Thanks for helping us test Highline!
        </div>
      </div>
      <div className={'beets-complete-sections'}>
        <div className={'beets-complete-section'}>
          <div className={'beets-complete-section-image logo-1'}></div>
          <div className={'beets-complete-section-text'}>
            You're now
            <br />
            automatically paying
            <br />${(window as any).payment_amount / 100}/month from payroll
          </div>
        </div>
        <div className={'beets-complete-section'}>
          <div className={'beets-complete-section-image logo-2'}></div>
          <div className={'beets-complete-section-text'}>
            As you make payments, reach out to{' '}
            <a href="mailto:help@highline.co">help@highline.co</a> with any
            questions or comments
          </div>
        </div>
        <div className={'beets-complete-section'}>
          <div className={'beets-complete-section-image logo-3'}></div>
          <div className={'beets-complete-section-text'}>
            After two months, you’ll be automatically unsubscribed from payments
          </div>
        </div>
      </div>
      <div className={'contact-text'}>
        {/* Reach out to <a href={'mailto:adam@highline.co'}>adam@highline.co</a>{' '}
        with any additional questions.
        <br /> Once testing is over, we’ll return your $8 to your checking
        account :) */}
      </div>
    </div>
  );
};
