import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  DemoApiRequestData,
  HighlineLinkResponse,
} from '../../types/memberOnboardingTypes';
import {
  getAccessToken,
  getUserMatch,
  postHighlineComplete,
} from '../../actions/EnrollmentActions';
import { runHighlineLinkDC } from '../../helpers/HighlineLinkHelpers';
import './MemberOnboardingBeetsCoffee.css';
import { ConfigContext } from '../../ConfigContext';
import {
  BeetsEmployeeFormInputs,
  BeetsSubscriptionForm,
} from '../../components/beets/beetsSubscriptionForm';
import { BeetsSuccess } from '../../components/beets/beetsSuccess';
import { BeetsEnterAmountForm } from '../../components/beets/beetsEnterAmountForm';
import { BeetsNotFound } from '../../components/beets/beetsNotFound';

export enum BEETS_SCREENS {
  CHECK_EMPLOYEE = 0,
  ENTER_AMOUNT = 1,
  NOT_FOUND = 2,
  SUCCESS = 3,
}

interface Props {
  partnerId?: string;
}

const ssn: string = '999999999';

export const MemberOnboardingBeetsCoffee: React.FC<Props> = () => {
  const [beetsScreen, setBeetsScreen] = useState(BEETS_SCREENS.CHECK_EMPLOYEE);
  const [applicantToken, setApplicantToken] = useState('');

  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error('Component must be used within a ConfigContext.Provider');
  }

  const memoizedConfig = useMemo(() => context.config, [context.config]);

  useEffect(() => {
    document.title = "Highline - Beet's Coffee";
    (window as any).highline_link_success = highlineLinkSuccess;
    (window as any).highline_link_error = highlineLinkError;
    (window as any).highline_link_close = highlineLinkClose;
  }, []);

  useEffect(() => {
    const productId = memoizedConfig.product_flow.beets_product_id;

    let demoApiRequestData: DemoApiRequestData = {
      feature: 'payments',
    };

    getAccessToken(
      (demoApiResponse: any) => {
        window.accessToken = demoApiResponse.access_token;
      },
      (errorMessage: any) => {
        alert(errorMessage);
      },
      memoizedConfig,
      productId,
      demoApiRequestData,
      () => {},
      () => {}
    );
  }, []);

  const highlineLinkSuccess = (data: HighlineLinkResponse) => {
    const productId = memoizedConfig.product_flow.beets_product_id;

    postHighlineComplete(
      () => setBeetsScreen(BEETS_SCREENS.SUCCESS),
      (errorMessage) => {
        console.log(errorMessage);
      },
      context.config,
      productId,
      (window as any).payment_amount,
      data.connection_id,
      data.user_id
    );
  };

  const highlineLinkError = () => {
    console.log('highlineLinkError');
  };

  const highlineLinkClose = () => {
    console.log('highlineLinkClose');
  };

  const onAmountFormSuccess = (amount: number) => {
    (window as any).payment_amount = Math.round(amount * 100);

    let demoApiRequestData: DemoApiRequestData = {
      feature: 'payments',
    };

    runHighlineLinkDC(
      window.accessToken,
      demoApiRequestData.feature,
      applicantToken
    );
  };

  const onCheckEmployeeSuccess = (values: BeetsEmployeeFormInputs) => {
    getUserMatch(
      (response) => {
        setBeetsScreen(BEETS_SCREENS.ENTER_AMOUNT);
        setApplicantToken(response.applicant_token);
      },
      () => setBeetsScreen(BEETS_SCREENS.NOT_FOUND),
      context.config,
      String(values.employee_id),
      String(values.first_name),
      String(values.last_name)
    );
  };

  return (
    <div className={'beets-wrapper'}>
      <div
        dangerouslySetInnerHTML={{
          __html: "<highline-link id='highline_link'/>",
        }}
      />
      <div className={'beets-toolbar'}>
        <div className={'beets-toolbar-left'}>
          <div className={'beets-toolbar-logo'}></div>
          <span className={'beets-toolbar-separator beets-toolbar-text'}>
            &nbsp;|&nbsp;
          </span>
          <div className={'beets-toolbar-text'}>
            <span className={'bold-text'}>Highline Beta Testing</span>
          </div>
        </div>
        <div className={'beets-toolbar-right'}>
          {/* <div className={"beets-toolbar-text"}>How It Works</div> */}
        </div>
      </div>

      <div>
        <div className={'beets-hero'}>
          <div className={'beets-hero-container'}>
            <div className={'beets-hero-image'}></div>
            <div className={'beets-hero-text'}>
              A fake coffee company used to test <br />
              Highline as a payment method. Always farm fresh.
            </div>
          </div>
        </div>
        {beetsScreen !== BEETS_SCREENS.SUCCESS && (
          <>
            <div className={'beets-our-products'}>
              <div className={'our-products-line'}></div>
              <div className={'our-products-text'}>Our Products</div>
              <div className={'our-products-line'}></div>
            </div>

            <div className={'beets-subscription-section'}>
              <div className={'beets-subscription-left'}></div>
              <div className={'beets-subscription-right'}>
                {beetsScreen === BEETS_SCREENS.CHECK_EMPLOYEE && (
                  <BeetsSubscriptionForm onSuccess={onCheckEmployeeSuccess} />
                )}

                {beetsScreen === BEETS_SCREENS.ENTER_AMOUNT && (
                  <BeetsEnterAmountForm onSuccess={onAmountFormSuccess} />
                )}

                {beetsScreen === BEETS_SCREENS.NOT_FOUND && (
                  <BeetsNotFound
                    setScreen={(form) => {
                      setBeetsScreen(form);
                    }}
                  />
                )}
              </div>
            </div>
          </>
        )}
        {beetsScreen === BEETS_SCREENS.SUCCESS && <BeetsSuccess />}
      </div>
    </div>
  );
};
