import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

// Type assertion ensures container is not null
const container = document.getElementById('root') as HTMLElement;

if (container) {
  const root = createRoot(container);
  root.render(<App />);
} else {
  console.error('Root container not found');
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
